import React from "react";

import './css/bank365.css';


class Bank365 extends React.Component {
  render() {
    return (
      <div>
        <div className="main-body">
          <div className="main-text">
            Відкрийте це посилання, будь ласка, у мобільному додатку «MyBank365»
          </div>
        </div>
      </div>
    );
  }
}

export default Bank365;
