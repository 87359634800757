import React from "react";
import axios from 'axios';
import { isAndroid } from 'react-device-detect';


const url = 'https://api.dev.clearinghouse.com.ua'
// const androidAppLinkProd = 'app://online.kapowai.clearinghouse/sdk_bankid'
const androidAppLinkStage = 'app://com.clhs.banx/sdk_bankid'
class Qr extends React.Component {
  state = {
    qrCode: "",
    url: "",
    stateId: ""
  }

  async componentDidMount() {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const dataReq = {
        response_type: queryParams.get('response_type'),
        client_id: queryParams.get('client_id'),
        redirect_uri: queryParams.get('redirect_uri'),
        state: queryParams.get('state')
      }
      const { data } = await axios.post(`${url}/api/v1/bank-id/save-state`, dataReq);
      const token = data.url.split('/')[data.url.split('/').length - 1];
      console.log(token, 'token');
      localStorage.setItem("token", token);
      this.checkAuthInfo()
      this.setState(data);
    } catch (error) {
      throw error.toString()
    }
  }

  async checkAuthInfo() {
    const getToken = localStorage.getItem("token");
    try {
      if(getToken) {
        const { data } = await axios.post(`${url}/api/v1/bank-id/get-auth-info`, { stateId: getToken });
        console.log(data, 'data.token');
        if(!data.token) {
          setTimeout(() => {
            this.checkAuthInfo();
          }, 2000)
        }
        const redirectUrl = `${data.redirectUrl}?code=${data.token}&state=${data.state}`;
        console.log('redirectUrl :', redirectUrl);
        window.location.href = redirectUrl;
        return null;
      }
    } catch (error) {
      console.log('checkAuthInfo error', error);
      setTimeout(() => {
        this.checkAuthInfo();
      }, 5000)
    }
  }

  render() {
    return (
      <div>
        <h2>Ідентифікація через Систему BankID НБУ</h2>
        <h2>Відскануйте QR-код камерою смартфону, щоб продовжити в&nbsp;додатку MyBank365</h2>
        <p>
          або натисніть на QR-код для переходу
          в&nbsp;додаток на&nbsp;вашому смартфоні.
        </p>
        {
          this.state.stateId ?
            <a
              className="qr-link"
              href={isAndroid ? `${androidAppLinkStage}/${this.state.stateId}` : this.state.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={this.state.qrCode} className="qr-img" alt="qr" />
            </a>
            : <h2>Ome moment. Loading...</h2>
        }
      </div>
    );
  }
}

export default Qr;
